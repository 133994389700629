import React from 'react';
import { Box, Typography, Link } from '@mui/material';
import useStyles from '../styles/styles';
import hex5_horizontal from '../images/hex5_horizontal.png';

const Footer = () => {
  const classes = useStyles();

  return (
    <Box sx={{ flexGrow: 1 }} className={classes.footerContainer}>
      <Link href="mailto:info@hex5.app" target="_blank" rel="noreferrer" className={classes.footerText} sx={{textDecoration: 'none'}}>
      info@hex5.app
      </Link>
      <Typography className={classes.footerText}>
        &copy; 2023
      </Typography>      
      <img src={hex5_horizontal} alt="Hex5" className={classes.logoFooter} />
    </Box>
  );
};

export default Footer;