import React from 'react';
import { Grid, Typography, Box } from '@mui/material';
import hex5_vendors from '../images/hex5_vendors.png';
import useStyles from '../styles/styles';
import ScrollAnchor from './ScrollAnchor';
import { Slide } from "react-awesome-reveal";

const Vendors = () => {
  const classes = useStyles();

  return (
    <Slide direction="left">
    <div>
    <ScrollAnchor  id="vendors" />
    <Box className={classes.aboutUsContainer}>
      <Grid container spacing={6} className={classes.gridContainer}>


        <Grid item xs={12} md={6}>
          <Typography variant="h4" fontWeight={700} className={classes.title}>
            Vendors
          </Typography>            
          <Typography variant="h5" fontWeight={700} className={classes.title}>
            relevant & trusted events
          </Typography>
          
          <Typography variant="h6" className={classes.aboutUsSubtitle}>
          Showcase your services at the right events and connect with verified organizers, 
          knowing that you are not dealing with scammers or fake accounts.
          Unlock new business opportunities and grow your brand with our dependable event platform.
          </Typography>
        </Grid>

        <Grid item xs={12} md={5}>
          <img src={hex5_vendors} alt="My Team" className={classes.largeImage} />
        </Grid>

      </Grid>
    </Box>
    </div>
    </Slide>
  );
};

export default Vendors;