import Hero from "./components/Hero"
import Header from "./components/Header"
// import Section from "./components/Section"
// import Testimonial from "./components/Testimonial"
import ContactUs from "./components/ContactUs"
import Footer from "./components/Footer"
import Organizers from "./components/Organizers"
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider} from '@mui/material/styles';
import { GlobalStyles } from '@mui/material';
import Vendors from "./components/Vendors"
import Users from "./components/Users"
import Together from "./components/Together";

// define the main colors of the theme
const mainColors = {
  primary_dark: '#16056B',
  primary_medium: '#5696FA',
  primary_light: '#D6E9FE',
  secondary_dark: '#FD9519',
  secondary_light: '#FFC988',
};

const theme = createTheme({
  palette: {
    primary: {
      main: mainColors.primary_dark,
      light: mainColors.primary_light,
      dark: mainColors.primary_dark,
    },
    secondary: {
      main: mainColors.secondary_dark,
      light: mainColors.secondary_light,
      dark: mainColors.secondary_dark,
    },
  },  
  typography: {
    fontFamily: [
      'Poppins',
      'sans-serif',
    ].join(','),
  },});

function App() {
  return (
    <>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles
      styles={{
        body: {
          scrollPaddingTop: '170px', // Adjust this value according to the height of your fixed header or other overlapping elements
        },
      }}
      />      
      <Header />
      <Hero />
      {/* <Section /> */}
      <Together />
      <Organizers />
      <Vendors />
      <Users />
      {/* <Testimonial /> */}
      <ContactUs />
      <Footer />
      </ThemeProvider>
    </>

  );
}

export default App;
