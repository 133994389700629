import React from 'react';
import { Grid, Typography, Button, Box } from '@mui/material';
import hex5_hero from '../images/hex5_hero.png';
import useStyles from '../styles/styles';

const Hero = () => {
  const classes = useStyles();

  return (
    <Box className={classes.heroBox}>
      <Grid container spacing={6} className={classes.gridContainer}>
        <Grid item xs={12} md={7}>
          <Typography variant="h4" fontWeight={700} className={classes.title}>
          Hex5.app 
          </Typography>
          <Typography variant="h5" fontWeight={700} className={classes.title}>
          Connects Trusted Organizers with Dependable Vendors for Unforgettable Events
          </Typography>          
          <Typography variant="h6" className={classes.subtitle}>
          Discover a marketplace that simplifies event planning by connecting organizers, vendors, and attendees. Experience a trusted and curated platform that ensures quality and reliability at every step.
          </Typography>
          <a href="https://www.facebook.com/Hex5app" 
              target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }} >
          <Button
            variant="contained"
            color="secondary"
            // set font color to primary
            sx={{ width: '300px', 
                  fontSize: '16px', 
                  fontWeight: '600',
                  borderRadius: '10px',
                  color: 'primary.main' }}
          >
            Follow us on Facebook
          </Button>
          </a>
        </Grid>
        <Grid item xs={12} md={5}>
          <img src={hex5_hero} alt="Hex5 App" className={classes.largeImage} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Hero;