import { makeStyles } from '@mui/styles';

const styles = (theme) => {
  return {      
    toolBar: {
      height: '7vh',
      display: 'flex',
      justifyContent: 'space-between',
      padding: '20px',
      backgroundColor: 'white',
      boxShadow: '0px 0px 0px 2px ' + theme.palette.primary.main,
    },
    logo: {
      color: 'red',
      cursor: 'pointer',
    },
    link: {
      color: theme.palette.primary.main,
      fontWeight: '600',
    },
    menuIcon: {
      color: '#000',
    },
    formContainer: {
      // flexGrow: 1,
      // padding: '20px',
      // // maxWidth: '700px',
      // margin: '30px auto',
      // [theme.breakpoints.between('xs', 'sm')]: {
      //   width: '100%',
      // },

      maxWidth: '1300px',
      width: '90%',
      display: 'flex',
      minHeight: '400px',
      alignItems: 'center',
      justifyContent: 'center',
      margin: '30px 0px 50px 0px',      
      backgroundColor: theme.palette.primary.light,
      borderRadius: '20px',      
    },
    form: {
      // marginTop: '30px',
    },
    formHeading: {
      textAlign: 'center',
    },
    heroBox: {
      width: '100%',
      display: 'flex',
      minHeight: '600px',
      alignItems: 'center',
      justifyContent: 'center',
    },
    gridContainer: {
      display: 'flex',
      alignItems: 'center',
      maxWidth: '1300px',
      padding: '50px',
    },
    aboutUsContainer: {
      width: '100%',
      display: 'flex',
      minHeight: '400px',
      alignItems: 'center',
      justifyContent: 'center',
      margin: '30px 0px 50px 0px',
    },
    aboutUsSubtitle: {
      opacity: '0.7',
      paddingBottom: '30px',
      fontSize: '18px',
    },
    title: {
      paddingBottom: '15px',
      color: theme.palette.primary.main,
    },
    subtitle: {
      opacity: '0.5',
      paddingBottom: '30px',
      color: 'black'
    },
    largeImage: {
      width: '100%',     
    },
    logoFooter: {
      width: '100px',
    },    
    sectionGridContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      minHeight: '500px',
    },
    sectionGridItem: {
      backgroundColor: '#f2f0f1',
      textAlign: 'center',
      padding: '30px',
      width: '200px',
      borderRadius: '10px',
      margin: '10px !important',
    },
    inputField: {
      marginBottom: '20px !important',
    },
    textArea: {
      width: '100%',
      marginBottom: '20px',
      fontSize: '16px',
      padding: '10px',
    },
    footerContainer: {
      display: 'flex',
      alignItems: 'center',
      height: '7vh',
      padding: '20px',
      justifyContent: 'space-between',
      backgroundColor: '#fff',
      flexDirection: 'row',
      boxShadow: '0px 0px 0px 2px ' + theme.palette.primary.main,
    },
    footerText: {
      // paddingBottom: '10px',
      // fontWeight: '800',
    },
    footerDate: {
      opacity: '0.4',
    },
    testimonialCard: {
      backgroundColor: '#fff',
      padding: '10px',
      minHeight: '200px',
      display: 'flex',
      alignItems: 'center',
    },
    testimonialStatement: {
      paddingBottom: '25px',
    },
    avatar: {
      marginRight: '10px',
    },
    testimonialPosition: {
      fontSize: '14px',
      opacity: '0.6',
    },
  };
};

const useStyles = makeStyles(styles);
export default useStyles;
