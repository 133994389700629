import React from 'react';
import { Grid, Typography, Box } from '@mui/material';
import hex5_users from '../images/hex5_users.png';
import useStyles from '../styles/styles';
import ScrollAnchor from './ScrollAnchor';
import { Slide } from "react-awesome-reveal";

const Users = () => {
  const classes = useStyles();

  return (
    <Slide direction="right">
    <div>
    <ScrollAnchor id="customers" />
    <Box className={classes.aboutUsContainer}>
      <Grid container spacing={6} className={classes.gridContainer}>

        <Grid item xs={12} md={5}>
          <img src={hex5_users} alt="My Team" className={classes.largeImage} />
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant="h4" fontWeight={700} className={classes.title}>
            Customers
          </Typography>            
          <Typography variant="h5" fontWeight={700} className={classes.title}>
            find unforgettable events
          </Typography>
          
          <Typography variant="h6" className={classes.aboutUsSubtitle}>
          Explore a world of unique events tailored to your interests. 
          Hex5 is not just an app for organizers and vendors is also a must have for event-goers.
          With Hex5.app, get ready to create lasting memories and connect with like-minded individuals.
          </Typography>
        </Grid>

      </Grid>
    </Box>
    </div>
    </Slide>
  );
};

export default Users;