import React from 'react';
import { Grid, Typography, Box } from '@mui/material';
import hex5_organizers from '../images/hex5_organizers.png';
import useStyles from '../styles/styles';
import ScrollAnchor from './ScrollAnchor';
import { Slide } from "react-awesome-reveal";

const Organizers = () => {
  const classes = useStyles();

  return (
    <Slide direction="right">
    <div>
    <ScrollAnchor id="organizers" />
    <Box className={classes.aboutUsContainer}>
      <Grid container spacing={6} className={classes.gridContainer}>

        <Grid item xs={12} md={5}>
          <img src={hex5_organizers} alt="My Team" className={classes.largeImage} />
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant="h4" fontWeight={700} className={classes.title}>
            Organizers
          </Typography>            
          <Typography variant="h5" fontWeight={700} className={classes.title}>
            seamless event management
          </Typography>
          
          <Typography variant="h6" className={classes.aboutUsSubtitle}>
          Find the perfect vendors for your events. 
          Effortlessly create and manage events with Hex5.app's intuitive tools and features. 
          Say goodbye to manual processes and focus on delivering unforgettable experiences 
          for your attendees.
          </Typography>
        </Grid>
      </Grid>
    </Box>
    </div>
    </Slide>
  );
};

export default Organizers;