import React from 'react';
// import useStyles from '../styles/styles';

const ScrollAnchor = ({ id }) => {
//   const classes = useStyles();

  return (
    <div
      id={id}
      style={{
        position: 'relative',
        // Adjust this value according to the height of your fixed header or other overlapping elements
        // top: '-' + classes.toolBar.height, 
        top: '-100px',
        visibility: 'hidden',
      }}
    />
  );
}

export default ScrollAnchor;
