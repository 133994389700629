import React, { useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Grid
} from '@mui/material';
import useStyles from '../styles/styles';
import hex5_light_bg from '../images/hex5_light_bg.png';
import hex5_work_light_bg from '../images/hex5_work_light_bg.png';
import { Slide } from "react-awesome-reveal";
import ScrollAnchor from './ScrollAnchor';
import validator from 'validator';

const ContactUs = () => {
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const classes = useStyles();


  const recordEmail = async () => {
    // send email to Google Form
    // https://docs.google.com/forms/d/e/1FAIpQLScMCjawqeCflEIZXU-ZvYFCcSHElZOkmrLZtCoLPXq57--3yQ/viewform?usp=pp_url&entry.346976689=terejanu@gmail.com

    const formID = '1FAIpQLScMCjawqeCflEIZXU-ZvYFCcSHElZOkmrLZtCoLPXq57--3yQ'; 
    const url = `https://docs.google.com/forms/d/e/${formID}/formResponse`;
    const emailField = 'entry.346976689';

    const formData = {
      [emailField]: email,
    };

    // console.log(formData);

    // Send the form data
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams(formData).toString(),
      mode: 'no-cors', // To avoid CORS issues
    });

    // this returns true if the form was submitted successfully
    return response.ok || response.type === 'opaque';

  }

  const submitForm = (e) => {
    e.preventDefault();
    if (email === '') {
      setSubmitted(false);
    }
    // if email is valid, set submitted to true
    if (validator.isEmail(email)) {
      setSubmitted(true);

      recordEmail().then((response) => {
        // console.log(response);
      }).catch((error) => {
        // console.log(error);
      });

    } else {
      setSubmitted(false);
    }    
    // console.log({ email });
  };

  const validateEmail = () => {
    // if email is empty, return false
    if (email === '') {
      return false;
    }
    // if email is valid, return false
    if (validator.isEmail(email)) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <Slide direction="left">
    <div>
    <ScrollAnchor id="waitinglist" />
    <Box className={classes.heroBox}>
    <Box className={classes.formContainer}>
      <Grid container spacing={6} className={classes.gridContainer} alignItems="center" justifyContent="center">

        <Grid item xs={6} md={2}>
          <img src={hex5_light_bg} alt="My Team" className={classes.largeImage} />
        </Grid>

        {!submitted ? (
        <Grid item xs={12} md={6}>
          <Typography variant="h4" fontWeight={700} className={classes.title}>
            Join our waiting list
          </Typography>
          <Typography variant="h6" className={classes.subtitle}>
              We are working hard to bring you the best event planning experience.
          </Typography>      
          <Box
            className={classes.form}
            component="form"
            noValidate
            autoComplete="off"
          >
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              className={classes.inputField}
              value={email}
              color="primary"
              onChange={(e) => setEmail(e.target.value)}
              error={validateEmail()}
              helperText={validateEmail() ? "Please enter a valid email address" : ""}
            />
            <Button
              variant="contained"
              type="submit"
              color="secondary"
              // set font color to primary
              sx={{ width: '250px', 
                    fontSize: '16px', 
                    fontWeight: '600',
                    borderRadius: '10px',
                    color: 'primary.main' }}          
              onClick={submitForm}
            >
              Join the waiting list
            </Button>
          </Box>
        </Grid>  
        ) : (
        <Grid item xs={12} md={6}>
          <Typography variant="h4" fontWeight={700} className={classes.title}>
            Thank you for joining our waiting list!
          </Typography>
          <Typography variant="h6" className={classes.subtitle}>
              Your email {email} has been added 
              to our waiting list. We will be in touch with you soon.
          </Typography>      
        </Grid>          
        )}

        <Grid item xs={12} md={4}>
          <img src={hex5_work_light_bg} alt="My Team" className={classes.largeImage} />
        </Grid>

      </Grid>
    </Box>
    </Box>
    </div>
    </Slide>
  );
};

export default ContactUs;